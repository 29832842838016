<template>
  <details ref="accordion" class="accordion">
    <summary
      ref="accordionSummary"
      class="accordion__summary"
      @click="onClick"
    >
      <slot name="summary-title" />
      <span
        v-if="$slots['summary-addition']"
        class="accordion__summary-addition"
        :class="{
          'accordion__summary-addition--size': isSize
        }"
      >
        <slot name="summary-addition" />
      </span>
    </summary>
    <div ref="accordionContent" class="accordion__content">
      <slot name="content" />
    </div>
  </details>
</template>

<script>
export default {
  name: 'CustomDetailsAccordion',
  props: {
    isSize: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      animation: null,
      isClosing: false,
      isExpanding: false,
    }
  },
  methods: {
    onClick(evt) {
      evt.preventDefault();

      this.$refs.accordion.style.overflow = 'hidden';
      if (this.isClosing || !this.$refs.accordion.open) {
        this.open();
      } else if (this.isExpanding || this.$refs.accordion.open) {
        this.shrink();
      }
    },
    shrink() {
      this.isClosing = true;

      const startHeight = `${this.$refs.accordion.offsetHeight}px`;
      const endHeight = `${this.$refs.accordionSummary.offsetHeight}px`;

      if (this.animation) {
        this.animation.cancel();
      }

      this.animation = this.$refs.accordion.animate({
        height: [startHeight, endHeight],
      }, {
        duration: 400,
        easing: 'ease-out',
      });

      this.animation.onfinish = () => this.onAnimationFinish(false);
      this.animation.oncancel = () => {
        this.isClosing = false
      };
    },
    open() {
      this.$refs.accordion.style.height = `${this.$refs.accordion.offsetHeight}px`;
      this.$refs.accordion.open = true;
      window.requestAnimationFrame(() => this.expand());
    },
    expand() {
      this.isExpanding = true;
      const startHeight = `${this.$refs.accordion.offsetHeight}px`;
      const endHeight = `${this.$refs.accordionSummary.offsetHeight + this.$refs.accordionContent.offsetHeight}px`;

      if (this.animation) {
        this.animation.cancel();
      }

      this.animation = this.$refs.accordion.animate({
        height: [startHeight, endHeight],
      }, {
        duration: 400,
        easing: 'ease-out',
      });

      this.animation.onfinish = () => this.onAnimationFinish(true);
      this.animation.oncancel = () => {
        this.isExpanding = false;
      }
    },
    onAnimationFinish(open) {
      this.$refs.accordion.open = open;
      this.animation = null;
      this.isClosing = false;
      this.isExpanding = false;
      this.$refs.accordion.style.height = this.$refs.accordion.style.overflow = '';
    },
  },
}
</script>
